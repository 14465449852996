import styled from "styled-components"
import { mediaMax } from "../../helpers/media-queries"

export const Wrapper = styled.section`
  padding: 80px 0px;
`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  gap: 90px;
  max-width: 720px;
`

export const SvgContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  svg {
    margin: 16px auto;
    min-height: 70px;
  }
  svg:first-child {
    width: 133px;
  }
  img {
    margin: 16px auto;
    background: transparent;
    &.rose-associates-icon {
      width: 168px;
      height: 60px;
    }
  }
  ${mediaMax('phoneXL')} {
    flex-direction: column;
    svg, img {
      margin: 26px auto;
    }
    svg:first-child {
      width: 130px;
    }
    img {
      &.rose-associates-icon {
        width: 123px;
        height: 44px;
      }
    }
  }
`

export const TextContainer = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-indent: 100px;
  font-style: italic;
  color: #47341e;
  ${mediaMax('phoneXL')} {
    text-align: center;
    text-indent: 0px;
  }

  p {
    margin-bottom: 40px;
  }

  a {
    font-style: normal;
  }
`
