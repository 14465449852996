import React, { useMemo } from "react"
import { DefaultLayout } from "../layout"
import { CookFoxIcon, MagIcon } from "../../icons"
import RoseAssociates from "../../icons/logo_rose_associates.png"
import CategoryLink from "../categories/category-link"
import ViewAnimation from "../animation/view-animation.jsx"
import {
  Wrapper,
  StyledContainer,
  SvgContainer,
  TextContainer
} from "./index.style"

const About = () => {
  return (
    <Wrapper>
      <ViewAnimation>
        <DefaultLayout>
          <StyledContainer>
            <SvgContainer>
              <MagIcon />
              <img className="rose-associates-icon" src={RoseAssociates} alt="Rose Associates" />
              <CookFoxIcon />
            </SvgContainer>
            <TextContainer>
              <p>
                Designed by celebrated architects COOKFOX Architects, the
                exterior of the building is contextual masonry inspired by the
                rich historic architectural fabric of the neighborhood, while
                the residences will incorporate biophilic design strategies that
                connect its residents to nature.
              </p>
              <CategoryLink title={"LEARN MORE"} link={"/team"} />
            </TextContainer>
          </StyledContainer>
        </DefaultLayout>
      </ViewAnimation>
    </Wrapper>
  )
}

export default About
